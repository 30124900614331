
import { Options, Vue } from "vue-class-component";
import emailjs from "emailjs-com";
import Button from "@/components/Button.vue";
import Footer from "@/components/layout/Footer.vue";

@Options({
  components: {
    Button,
    Footer,
  },
})
export default class Contact extends Vue {
  private name = "";
  private email = "";
  private subject = "";
  private message = "";

  private resetForm() {
    this.name = "";
    this.email = "";
    this.subject = "";
    this.message = "";
  }

  private sendEmail() {
    // Validate Name
    if (this.name.length === 0) {
      alert("Please enter in a name");
      return;
    }
    // Validate Email
    if (this.email.length === 0) {
      alert("Please enter in an email");
      return;
    }
    const emailRegex = /\S+@\S+/;
    if (!emailRegex.test(this.email.toLowerCase())) {
      alert("Please enter a valid email");
      return;
    }
    // Validate Message
    if (this.message.length === 0) {
      alert("Please enter a message");
      return;
    }
    // All clear, send email
    emailjs
      .sendForm(
        "default_service",
        "contact_me",
        this.$refs["form"] as HTMLFormElement,
        "user_KPA6UZpEDVA0yIJHW5WPo"
      )
      .then(
        (result) => {
          window.console.log("Success", result.status, result.text);
          alert("Your email has been sent!");
        },
        (error) => {
          window.console.log("Error", error);
          alert("There was an error with your email.");
        }
      );
  }
}
